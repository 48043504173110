import(/* webpackMode: "eager" */ "/vercel/path0/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/apple-wallet.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/google.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/klarna.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/mastercard.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/paypal.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/visa.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/ventiq_Logo_Design_V1.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CartCheckInterval.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/CartTimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cookies"] */ "/vercel/path0/components/Cookies/Cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Footer/components/CookieSettings.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Footer/components/LanguageButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/CartAmount.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/Languages.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/NavigationSearch.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/Script.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/User.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payment/PayPalWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/utils/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Anton\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-anton\",\"weight\":[\"400\"]}],\"variableName\":\"fontAnton\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Libre_Barcode_39\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-barcode\",\"weight\":[\"400\"]}],\"variableName\":\"fontBarcode\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Alfa_Slab_One\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-alfa-slab\",\"weight\":[\"400\"]}],\"variableName\":\"fontAlfaSlab\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Archivo_Black\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-archivo\",\"weight\":[\"400\"]}],\"variableName\":\"fontArchivo\"}");
